import React, { Component } from 'react';
import { CheckCircle, XCircle } from 'react-feather';
import AgePicker from '../agePicker';
import Check from '../check';
import Modal from '../modal';
import { Link } from 'gatsby';

class EventVerify extends Component {
  state = { showModal: false, inputs: {}, errors: {}, disabled: false };
  render() {
    return (
      <div>
        <div className='container py-4 text-center bg-white booking-verify'>
          <h5>Confirm Your Sky Fest Booking</h5>

          <button
            className='btn btn-primary mt-3 px-5 btn-round'
            onClick={() => {
              this.setState({ showModal: true });
            }}>
            Confirm
          </button>
        </div>

        <Modal
          visible={this.state.showModal}
          className='fest-event event-modal'>
          <form
            className='event-form shadow p-4'
            onSubmit={(e) => {
              e.preventDefault();
              this.verify();
            }}>
            <h3 className='mt-4 text-primary form-title'>
              CONFIRM YOUR BOOKING
            </h3>

            <div className='row'>
              <div className='form-group mb-3 col-md-12 my-5'>
                <label className='text-primary form-label'>MOBILE NUMBER</label>
                <input
                  type='number'
                  onChange={(e) => {
                    let { inputs } = this.state;
                    inputs.phone = e.target.value;
                    this.setState({ inputs, errors: {} });
                  }}
                  className='form-control'
                  required={true}
                />

                {this.state.errors.phone && (
                  <small className='text-red' id='phone-error'>
                    Phone Number is not valid
                  </small>
                )}
              </div>

              <div className='col-md-12 text-center mt-4'>
                <button className='btn btn-primary px-4 fomt-weight-bold btn-round'>
                  Confirm
                </button>
              </div>
            </div>

            {this.state.showParent && (
              <div className='floating-form p-4 p-md-5'>
                <div id='form-content'>
                  {this.state.loading && (
                    <div className='text-center'>
                      <div class='sk-chase d-inline-block'>
                        <div class='sk-chase-dot'></div>
                        <div class='sk-chase-dot'></div>
                        <div class='sk-chase-dot'></div>
                        <div class='sk-chase-dot'></div>
                        <div class='sk-chase-dot'></div>
                        <div class='sk-chase-dot'></div>
                      </div>
                      <p className='text-center mt-4'>loading ...</p>
                    </div>
                  )}

                  {!this.state.loading &&
                    !this.state.success &&
                    !this.state.bookingError && (
                      <div className='form-content'>
                        <CheckCircle
                          size={60}
                          className='text-primary'></CheckCircle>
                        <br />
                        <br />
                        <div>
                          <p>Will you still be attending?</p>
                          <br />
                          <div>
                            <button
                              className='btn btn-primary'
                              onClick={() => {
                                this.updateTicket(2);
                              }}>
                              YES
                            </button>
                            <button
                              className='btn btn-outline-primary mx-3'
                              onClick={() => {
                                this.updateTicket(3);
                              }}>
                              MAYBE
                            </button>
                            <button
                              className='btn btn-outline-primary'
                              onClick={() => {
                                this.updateTicket(-1);
                              }}>
                              NO
                            </button>
                          </div>
                        </div>
                      </div>
                    )}

                  {!this.state.loading &&
                    this.state.success &&
                    !this.state.bookingError && (
                      <div className='form-content'>
                        <Check></Check>

                        {this.state.bookingMessage}

                        {/* <small>
                            <a href=''>I haven’t received an SMS</a>
                          </small>
                          <p
                            onClick={() => {
                              this.setState({
                                showParent: false,
                                loading: false,
                              });
                            }}>
                            reset
                          </p> */}
                      </div>
                    )}

                  {!this.state.loading && this.state.bookingError && (
                    <div className='form-content'>
                      <div className='text-center text-red mb-4'>
                        <XCircle size={60}></XCircle>
                      </div>
                      <p>{this.state.bookingError}</p>

                      <p className='text-center'>
                        <Link
                          to='/SKY-fest'
                          className='btn btn-outline-primary px-4 mr-3'
                          onClick={() =>
                            this.setState({
                              showParent: false,
                              loading: false,
                              bookingError: false,
                              showParent: false,
                              showModal: false,
                            })
                          }>
                          Register
                        </Link>
                        <button
                          className='btn btn-outline-primary px-4'
                          onClick={() =>
                            this.setState({
                              showParent: false,
                              loading: false,
                              bookingError: false,
                            })
                          }>
                          Retry
                        </button>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            )}
          </form>
        </Modal>
      </div>
    );
  }

  formatNumber = (num) => {
    num = num.replace(/(\+|\s)/g, '');
    num = parseInt(num) + '';
    if (num.substring(0, 3) !== '254') {
      num = '254' + num;
    }
    return num;
  };

  verify = () => {
    console.log(this.state.inputs);
    let { inputs } = this.state;

    inputs.phone = this.formatNumber(inputs.phone);

    if (inputs.phone.length !== 12) {
      this.setState({
        errors: {
          phone: '*Phone Number is invalid*',
        },
      });
      setTimeout(() => {
        document
          .querySelector('#phone-error')
          .scrollIntoView({ behavior: 'smooth', block: 'center' });
      }, 0);

      return;
    }

    this.setState({ loading: true, showParent: true });

    window
      .fetch(`${global.server}/event-verify`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(this.state.inputs),
      })
      .then((response) => response.json())
      .then((response) => {
        console.log(response.code);

        if (response.code) {
          this.setState({ bookingError: response.message, loading: false });

          return;
        }

        this.setState({ loading: false, response: { id: response.id } });
      })
      .catch((err) => {
        console.error(err);
      });
  };

  updateTicket = (status) => {
    window
      .fetch(`${global.server}/event-verify/` + this.state.response.id, {
        method: 'PATCH',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ status }),
      })
      .then((response) => response.json())
      .then((response) => {
        if (response.code) {
          this.setState({ bookingError: response.message, loading: false });

          return;
        }

        let messages = {
          2: (
            <>
              <p>
                Your Booking has been verified
                <br />A confirmation SMS with a link to your virtual ticket has
                been sent to your phone.
              </p>
              <p>The ticket will contain all your booking information</p>
            </>
          ),
          3: (
            <>
              <p>
                Your Booking has been verified
                <br />A confirmation SMS with a link to your virtual ticket has
                been sent to your phone.
              </p>
              <p>The ticket will contain all your booking information</p>
            </>
          ),
          '-1': (
            <>
              <p>We have recieved your response successfully.</p>
            </>
          ),
        };

        this.setState({ success: true, bookingMessage: messages[status] });
      })
      .catch((err) => {
        console.error(err);
      });
  };
}

export default EventVerify;
