import React, { Component } from 'react';
import { Link } from 'gatsby';
import BannerComponent from './bannerComponent';

class TextSectionHorizontal extends Component {
  state = {};
  render() {
    return (
      <section
        className={`${this.props.background_color} ${this.props.className} ${this.props.text_align}`}>
        <div className='py-md-5 container'>
          <div className='row py-5'>
            <div className='col-md-6 text-col'>
              <h1
                className={`intro-title ${this.props.title_color} ${this.props.title_font}`}>
                {this.props.title}
              </h1>
              <div className='mt-4'>
                <div
                  className={`${this.props.content_color} ${this.props.content_font} text-content `}
                  dangerouslySetInnerHTML={{
                    __html: this.props.content,
                  }}></div>

                {this.props.cta_button == 1 && (
                  <Link
                    to={this.props.button_link}
                    className='btn btn-primary px-4 btn-round my-3'>
                    {this.props.button_text}
                  </Link>
                )}
              </div>
            </div>
            <div className='col-md-6 my-3 my-md-0 banner-col'>
              {this.props.banner && (
                <BannerComponent
                  value={this.props.banner}
                  type={this.props.banner_type}></BannerComponent>
              )}
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default TextSectionHorizontal;
